<template>
  <div class="admin-bonus d-flex flex-column">
    <div class="header">
      <div class="mr-auto d-flex align-items-center">
        <h1 class="title mb-0">
          {{ $t("lk.admin_bonus_transfer.title") }}
        </h1>
      </div>
    </div>

    <div class="mb-3 table-nav">
      <div class="table-nav__actions">
        <p class="mb-0">{{ $t("lk.referrals.set_status") }}:</p>
        <CLoadingButtonCustom
          color="secondary"
          :loading="loading.pending"
          :disabled="!hasSelected"
          @click.native="setStatus('pending')"
        >
          <CIcon class="mt-0" name="cil-clock" size="sm" />
          {{ $t("lk.referrals.status.pending") }}
        </CLoadingButtonCustom>
        <CLoadingButtonCustom
          color="secondary"
          :loading="loading.new"
          class="text-warning"
          :disabled="!hasSelected"
          @click.native="setStatus('new')"
        >
          <CIcon class="mt-0" name="cil-x-circle" size="sm" />
          {{ $t("lk.referrals.status.new") }}
        </CLoadingButtonCustom>
        <CLoadingButtonCustom
          color="danger"
          :loading="loading.canceled"
          class="text-black-50"
          :disabled="!hasSelected"
          @click.native="setStatus('canceled')"
        >
          <CIcon class="mt-0" name="cil-x-circle" size="sm"/>
          Отклонить
        </CLoadingButtonCustom>
        <CLoadingButtonCustom
          color="secondary"
          :loading="loading.succeeded"
          class="text-success"
          :disabled="!hasSelected"
          @click.native="setStatus('succeeded')"
        >
          <CIcon class="mt-0" name="cil-check-circle" size="sm" />
          {{ $t("lk.referrals.status.succeeded") }}
        </CLoadingButtonCustom>
      </div>
      <div class="table-nav__actions">
        <label class="mb-0">
          <CButton
            color="secondary"
            @click="selectedAllPending = !selectedAllPending"
          >
            <CInputCheckbox
              :checked="selectedAllPending"
              :indeterminate="typeof selectedAllPending !== 'boolean'"
              custom
              @click.native.prevent
            >
              <template #description>
                {{ $t("lk.referrals.select_all_not_paid") }}
              </template>
            </CInputCheckbox>
          </CButton>
        </label>
        <CButton
          v-tooltip="$t('lk.referrals.upload_hint')"
          color="success"
          @click="saveXML"
        >
          <CIcon class="mt-0" name="cil-cloud-download" size="sm" />
          {{ $t("lk.referrals.upload_selected") }}
        </CButton>
      </div>
    </div>

    <div class="h-100 overflow-hidden">
      <CCard color="second-card-bg" class="ccard shadow-none h-100">
        <CCardBody class="ccard-body d-flex flex-column">
          <div class="table-wrap overflow-auto">
            <table class="table mb-0">
              <thead class="text-nowrap">
                <tr>
                  <th>
                    <CInputCheckbox
                      :checked.sync="selectedAll"
                      :indeterminate="typeof selectedAll !== 'boolean'"
                      custom
                    />
                  </th>
                  <th v-for="field in fields" :key="field.key">
                    {{ field.label }}
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in items" :key="item.data.id">
                  <th>
                    <CInputCheckbox :checked.sync="item.selected" custom />
                  </th>
                  <td :class="'text-' + getStatusMessage(item.data).color">
                    {{ getStatusMessage(item.data).title }}
                  </td>
                  <td>
                    {{
                        item.data.user.tg_nick ?? null
                    }}
                  </td>
                  <td>
                    {{ item.data.user.email }}
                  </td>
                  <td>
                    {{ Math.abs(item.data.cost) }}
                  </td>
                  <td>
                    {{ (item.data.meta && item.data.meta.wallet) || "-" }}
                  </td>
                  <td>
                    {{ getDateMessage(item.data) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import _get from "lodash/get";

export default {
  name: "lk-admin-bonus-transfer",
  components: {},
  data() {
    return {
      /** @type {{data: BonusTransferTransactionResource; selected: boolean}[]} */
      items: [],
      /** @type boolean */
      isSelectedPending: false,
      /** @type BonusTransferTransactionResource[] */
      selectedItems: [],
      loading: {
        pending: false,
        new: false,
        canceled: false,
        succeeded: false,
      },
    };
  },
  computed: {
    lodashGet() {
      return _get
    },
    fields() {
      return [
        { key: "status", label: this.$t("lk.referrals.table.header.status") },
        {
          key: "telegram",
          label: this.$t("lk.referrals.table.header.telegram"),
        },
        {
          key: "email",
          label: this.$t("lk.referrals.table.header.system_login"),
        },
        { key: "cost", label: this.$t("lk.referrals.table.header.cash_cost") },
        { key: "wallet", label: this.$t("lk.referrals.table.header.wallet") },
        { key: "created_at", label: this.$t("lk.referrals.table.header.date") },
      ];
    },
    /** @type boolean|null */
    selectedAll: {
      get() {
        if (this.items.every((i) => i.selected)) {
          return true;
        } else if (this.items.every((i) => !i.selected)) {
          return false;
        } else {
          return null;
        }
      },
      set(selected) {
        this.items = this.items.map(({ data }) => ({
          data,
          selected,
        }));
      },
    },
    /** @type boolean|null */
    selectedAllPending: {
      get() {
        const isStatusSuccess = (item) => item.status === "succeeded";
        if (
          this.items.every(
            (i) =>
              (i.selected && !isStatusSuccess(i.data)) ||
              (!i.selected && isStatusSuccess(i.data))
          )
        ) {
          return true;
        } else if (this.items.every((i) => !i.selected)) {
          return false;
        } else {
          return null;
        }
      },
      set(status) {
        const isStatusSuccess = (item) => item.status === "succeeded";

        this.items = this.items.map(({ data }) => ({
          data,
          selected: status && !isStatusSuccess(data),
        }));
      },
    },
    /** @type boolean|null */
    hasSelected() {
      return this.items.some((i) => i.selected);
    },
    /**
     * @return {(item: BonusTransferTransactionResource) => {color: string, title: string}}
     */
    getStatusMessage() {
      return (item) => {
        switch (item.status) {
          case "succeeded": {
            return {
              color: "success",
              title: this.$t("lk.referrals.status.succeeded"),
            };
          }

          case "pending": {
            return {
              color: "white",
              title: this.$t("lk.referrals.status.pending"),
            };
          }

          case "new": {
            return {
              color: "warning",
              title: this.$t("lk.referrals.status.new"),
            };
          }

          case "canceled": {
            return {
              color: "danger",
              title: this.$t("lk.referrals.status.cancel"),
            };
          }
        }
      };
    },
  },
  beforeMount() {
    this.fetchItems();
  },
  methods: {
    fetchItems() {
      axios.get("/api/v2/bonuses/transfers").then((res) => {
        this.items = res.data.data.map((data) => ({
          data,
          selected: false,
        }));
      });
    },
    /**
     * @param {BonusTransferTransactionResource} item
     * @return {string}
     */
    getDateMessage(item) {
      return moment(item.created_at).format("DD.MM.yyyy");
    },
    saveXML() {
      import("xlsx").then((XLSX) => {
        const workbook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.json_to_sheet(
          this.items
            .filter((i) => i.selected)
            .map(({ data }) => ({
              Currency: "USDTTRC20",
              Address: (data.meta && data.meta.wallet) || "-",
              ExtraId: "",
              Amount: Math.abs(data.cost),
            }))
        );
        XLSX.utils.book_append_sheet(
          workbook,
          workSheet,
          this.$t("lk.admin_bonus_transfer.xlsx.uploadingBonusTransactions")
        );
        const filename =
          "bonuses_transaction_" +
          moment(Date.now()).format("HH_mm_DD_MM_yyyy") +
          ".xlsx";
        XLSX.writeFile(workbook, filename);
      });
    },
    setStatus(status) {
      const selected = this.items.filter((i) => i.selected).map((i) => i.data);
      const transactions = selected
        .filter((i) => i.status !== status)
        .map(({ id }) => ({
          id,
          status,
        }));

      this.$set(this.loading, status, true);
      axios
        .post("/api/v2/bonuses/transfers/change-status", { transactions })
        .then(() => {
          this.fetchItems();
        })
        .finally(() => {
          this.$set(this.loading, status, false);
        });
    },
  },
};
</script>


<style lang="scss" scoped>
//@import "@/assets/scss/style";
.table-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
.header {
  display: flex;
  align-items: center;

  @include media-breakpoint-down(xs) {
    display: none;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}

.separator {
  margin: 2.5rem 0;
}

.icon {
  margin: 0;
}

.subactions {
  .ccol {
    display: flex;
  }

  @include media-breakpoint-down(xs) {
    .ccol {
      flex-direction: column;
      align-items: center;
    }
  }
}

.cinput /deep/ .form-control,
.cbtn {
  height: 2.75rem;
}

.tg-button {
  width: 13rem;
}

.verify-message {
  font-size: 0.75rem;
}

.app {
  &--horizontal-line {
    width: 1px;
    background-color: var(--white);
    opacity: 0.1;

    &-transparent {
      background-color: transparent;
    }
  }
}

.page-lk-referral {
  &--body {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1px 1fr;
  }

  &--icon-prefix {
    font-size: 25px;

    &-success {
      color: var(--success);
      font-weight: bold;
    }
  }

  &--input-all {
    border-bottom: dashed 1px rgba(69, 161, 100, 1);
    transition: all 200ms ease-out;
    cursor: pointer;

    &:hover {
      border-color: rgba(69, 161, 100, 0);
    }
  }
}

.h-44 {
  min-height: 44px;
}

.table {
  thead {
    th {
      position: sticky;
      top: -1px;
      background: var(--second-card-bg);
    }
  }
}

.admin-bonus {
  @include media-breakpoint-down(lg) {
    @media (min-height: 500px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 600px) {
      height: 100%;
    }
  }
}
</style>

<style>
input[type="checkbox"][indeterminate] + label::before {
  background-color: #321fdb;
  border-color: #321fdb;
  background-image: var(
    --cui-form-check-input-indeterminate-bg-image,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='rgba%28255, 255, 255, 0.87%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
  );
}
</style>
