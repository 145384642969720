var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "admin-bonus d-flex flex-column" }, [
    _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "mr-auto d-flex align-items-center" }, [
        _c("h1", { staticClass: "title mb-0" }, [
          _vm._v(" " + _vm._s(_vm.$t("lk.admin_bonus_transfer.title")) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "mb-3 table-nav" }, [
      _c(
        "div",
        { staticClass: "table-nav__actions" },
        [
          _c("p", { staticClass: "mb-0" }, [
            _vm._v(_vm._s(_vm.$t("lk.referrals.set_status")) + ":"),
          ]),
          _c(
            "CLoadingButtonCustom",
            {
              attrs: {
                color: "secondary",
                loading: _vm.loading.pending,
                disabled: !_vm.hasSelected,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setStatus("pending")
                },
              },
            },
            [
              _c("CIcon", {
                staticClass: "mt-0",
                attrs: { name: "cil-clock", size: "sm" },
              }),
              _vm._v(" " + _vm._s(_vm.$t("lk.referrals.status.pending")) + " "),
            ],
            1
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "text-warning",
              attrs: {
                color: "secondary",
                loading: _vm.loading.new,
                disabled: !_vm.hasSelected,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setStatus("new")
                },
              },
            },
            [
              _c("CIcon", {
                staticClass: "mt-0",
                attrs: { name: "cil-x-circle", size: "sm" },
              }),
              _vm._v(" " + _vm._s(_vm.$t("lk.referrals.status.new")) + " "),
            ],
            1
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "text-black-50",
              attrs: {
                color: "danger",
                loading: _vm.loading.canceled,
                disabled: !_vm.hasSelected,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setStatus("canceled")
                },
              },
            },
            [
              _c("CIcon", {
                staticClass: "mt-0",
                attrs: { name: "cil-x-circle", size: "sm" },
              }),
              _vm._v(" Отклонить "),
            ],
            1
          ),
          _c(
            "CLoadingButtonCustom",
            {
              staticClass: "text-success",
              attrs: {
                color: "secondary",
                loading: _vm.loading.succeeded,
                disabled: !_vm.hasSelected,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.setStatus("succeeded")
                },
              },
            },
            [
              _c("CIcon", {
                staticClass: "mt-0",
                attrs: { name: "cil-check-circle", size: "sm" },
              }),
              _vm._v(
                " " + _vm._s(_vm.$t("lk.referrals.status.succeeded")) + " "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-nav__actions" },
        [
          _c(
            "label",
            { staticClass: "mb-0" },
            [
              _c(
                "CButton",
                {
                  attrs: { color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.selectedAllPending = !_vm.selectedAllPending
                    },
                  },
                },
                [
                  _c("CInputCheckbox", {
                    attrs: {
                      checked: _vm.selectedAllPending,
                      indeterminate:
                        typeof _vm.selectedAllPending !== "boolean",
                      custom: "",
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "description",
                        fn: function () {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("lk.referrals.select_all_not_paid")
                                ) +
                                " "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "CButton",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.$t("lk.referrals.upload_hint"),
                  expression: "$t('lk.referrals.upload_hint')",
                },
              ],
              attrs: { color: "success" },
              on: { click: _vm.saveXML },
            },
            [
              _c("CIcon", {
                staticClass: "mt-0",
                attrs: { name: "cil-cloud-download", size: "sm" },
              }),
              _vm._v(
                " " + _vm._s(_vm.$t("lk.referrals.upload_selected")) + " "
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "h-100 overflow-hidden" },
      [
        _c(
          "CCard",
          {
            staticClass: "ccard shadow-none h-100",
            attrs: { color: "second-card-bg" },
          },
          [
            _c("CCardBody", { staticClass: "ccard-body d-flex flex-column" }, [
              _c("div", { staticClass: "table-wrap overflow-auto" }, [
                _c("table", { staticClass: "table mb-0" }, [
                  _c("thead", { staticClass: "text-nowrap" }, [
                    _c(
                      "tr",
                      [
                        _c(
                          "th",
                          [
                            _c("CInputCheckbox", {
                              attrs: {
                                checked: _vm.selectedAll,
                                indeterminate:
                                  typeof _vm.selectedAll !== "boolean",
                                custom: "",
                              },
                              on: {
                                "update:checked": function ($event) {
                                  _vm.selectedAll = $event
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._l(_vm.fields, function (field) {
                          return _c("th", { key: field.key }, [
                            _vm._v(" " + _vm._s(field.label) + " "),
                          ])
                        }),
                        _c("th"),
                      ],
                      2
                    ),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.items, function (item) {
                      return _c("tr", { key: item.data.id }, [
                        _c(
                          "th",
                          [
                            _c("CInputCheckbox", {
                              attrs: { checked: item.selected, custom: "" },
                              on: {
                                "update:checked": function ($event) {
                                  return _vm.$set(item, "selected", $event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            class:
                              "text-" + _vm.getStatusMessage(item.data).color,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getStatusMessage(item.data).title) +
                                " "
                            ),
                          ]
                        ),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(item.data.user.tg_nick ?? null) + " "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(item.data.user.email) + " "),
                        ]),
                        _c("td", [
                          _vm._v(" " + _vm._s(Math.abs(item.data.cost)) + " "),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " +
                              _vm._s(
                                (item.data.meta && item.data.meta.wallet) || "-"
                              ) +
                              " "
                          ),
                        ]),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.getDateMessage(item.data)) + " "
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }